import React from "react";
import "./Nip.css";

import { useState } from "react";
import { Link } from "react-router-dom";

import TapMe from "./images/page1/TapMe.png";
import home from "./images/Home.png";

import TwitterM from "./images/TwitterM.png";
import FelineFiendzIcon from "./images/FelineFiendzIcon.png";
import OpenseaM from "./images/OpenseaM.png";

import Discord from "./images/Discord.png";
function Nip() {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <div className="Nip">
      <img
        onClick={toggleClass}
        className="TapMe bounce-in-top-ss"
        src={TapMe}
        alt=""
      />
      <Link className="linkToStore" to="/bodega">
        <img alt="" className="homeBTN" src={home}></img>
      </Link>

      <div className="nip-container">
        <h1>The Bodega</h1>
        <div className="nip-context">
          <p>
            The feline fiendz bodega is our take on the traditional nft
            marketplace.
          </p>
          <p>
            It currently lives entirely in our discord and the currency is
            called $NIP.
          </p>
          <p>
            In the Bodega you can purchase not only desireable NFTs but also
            whitelist opportunities,
          </p>
          <p>
            NFT raffle tickets, physical merchandise, and custom 1/1 art
            experiences.
          </p>
          <p>
            The Bodega is powered by $NIP, which you can collect simply by
            owning fiendz.
          </p>
          <p>
            Instead of forcing holders to stake their nfts, the $NIP system
            allows your property to stay in your wallet.
          </p>
          <p>
            The more fiendz you hold in your wallet, the more $NIP you can
            collect every day.
          </p>
          <p>
            Find out more about the Bodega and browse it's contents in the
            discord.
          </p>
          <h1 className="tokenNIP">$NIP</h1>
          <p>The native currency for the bodega is called $nip.</p>
          <p>
            The amount 0f $nip you earn per day, corresponds to how many fiendz
            you own.
          </p>
          <p>The more fiendz you own, the more $nip you earn per day</p>
          <p>$Nip has to be collected in our discord.</p>
          <p>For more information go to our discord server.</p>
        </div>
      </div>
      <div className="mobile-socials">
        <div>
          <a href="/">
            <img className="FelineFiendzIcon" src={FelineFiendzIcon} alt="" />
          </a>
        </div>
        <div className="mobile-socials-right">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://opensea.io/collection/felinefiendznft"
          >
            <img className="OpenseaM" src={OpenseaM} alt="" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/DGpXcvHCEr">
            <img className="Discord" src={Discord} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw"
          >
            <img className="TwitterM" src={TwitterM} alt="" />
          </a>
        </div>
      </div>
      <div className="footer">
        {/* <img className="Footer1" src={Footer1} alt="" /> */}
        <p>© 2022 Fiendz, LLC. All Rights Reserved.</p>
      </div>
      <div>
        {isActive && (
          <div className="NavBar bounce-in-top-ss">
            <Link to="/">
              <img className="home-btn-navbar" src={home} alt="" />
            </Link>
            <div className="NavBar-container">
              <ul>
                <li>
                  <Link to="/roadmap">ROADMAP</Link>
                </li>
                <li>
                  <Link to="/merch">MERCH</Link>
                </li>
                <li>
                  <Link to="/traits">TRAITS</Link>
                </li>
                <li>
                  <Link to="/team">TEAM</Link>
                </li>
                <li>
                  <Link to="/nip">NIP</Link>
                </li>
                <li id="legalLi">
                  <Link to="/legal">
                    <p id="Legal-Navbar"> Legal</p>
                  </Link>
                </li>
              </ul>
            </div>
            <img onClick={toggleClass} className="TapMe2" src={TapMe} alt="" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Nip;
