import React from "react";
import "./Team.css";

import { useState } from "react";
import { Link } from "react-router-dom";

import TapMe from "./images/page1/TapMe.png";
import home from "./images/Home.png";

import Bolivian from "./images/Team/Bolivian.png";
import Chulo from "./images/Team/Chulo.png";
import Buttercup from "./images/Team/Buttercup.png";
import Mahhhkiss from "./images/Team/Mahhhkiss.png";
import Hub from "./images/Team/Hub.png";
import TCity from "./images/Team/T City.png";
import Vader from "./images/Team/Vader.jpg";
import Mintonomics from "./images/Team/Mintonomics.png";
import Stickyz from "./images/Team/Stickyz.png";

import Discord from "./images/Discord.png";

import TwitterM from "./images/TwitterM.png";
import FelineFiendzIcon from "./images/FelineFiendzIcon.png";
import OpenseaM from "./images/OpenseaM.png";

function Team() {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <div className="Team">
      <img
        onClick={toggleClass}
        className="TapMe bounce-in-top-ss"
        src={TapMe}
        alt=""
      />
      <Link className="linkToStore" to="/bodega">
        <img alt="" className="homeBTN" src={home}></img>
      </Link>

      <div className="team-container">
        <h1>Meet The Team</h1>
        <div className="team-context">
          <div>
            <p className="feline-context">Who are the Feline Fiendz?</p>
            <p className="feline-context">
              We are a collection of 7777 Feline Fiendz located on the Ethereum
              blockchain. An exclusive brand for degens by degens.
            </p>
            <p className="feline-context">
              You can find other like minded individuals in Our community
              ranging from artists to web developers, from parents to
              hypebeasts, and everyone in between.
            </p>
            <p className="feline-context">
              We do not shy away from how diverse our community is and continue
              to grow as we prosper in the web3 space.
            </p>
            <p className="feline-context">
              Our project offers utility with exclusive airdrops, real life
              events, limited edition clothing drops and much more to come.
            </p>
            <p className="feline-context">See you in the Litter box!</p>
          </div>
          <h1 className="teamMembers">Fiendz Team</h1>
          <img className="teamMembers-image" src={Bolivian} alt="" />
          <h1>Bolivian</h1>
          <p>Co-Founder</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/_bolivian">
            <p className="twitterHandle">@_Bolivian</p>
          </a>
          <img className="teamMembers-image" src={Chulo} alt="" />
          <h1>Chulo</h1>
          <p>Co-Founder</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/chulo_chris_">
            <p className="twitterHandle">@Chulo_CHRIS</p>
          </a>

          <img className="teamMembers-image" src={Buttercup} alt="" />
          <h1>Buttercup</h1>
          <p>Co-Founder</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/buttercupnft">
            <p className="twitterHandle">@BUTTERCUPNFT</p>
          </a>

          <img className="teamMembers-image" src={Mahhhkiss} alt="" />
          <h1>Mahhhkiss</h1>
          <p>Project Manager</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/Mahhhhkiss7">
            <p className="twitterHandle">@Mahhhkiss</p>
          </a>

          <img className="teamMembers-image" src={Hub} alt="" />
          <h1>Hub</h1>
          <p>Project Manager</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/DnDHuub">
            <p className="twitterHandle">@DNDHUUB</p>
          </a>

          <img className="teamMembers-image" src={TCity} alt="" />
          <h1>T City</h1>
          <p>Team Admin</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/cityboynft">
            <p className="twitterHandle">@CITYBOYNFT</p>
          </a>

          <img className="teamMembers-image" src={Vader} alt="" />
          <h1>Vader</h1>
          <p>Team Admin</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/FelineSithLord">
            <p className="twitterHandle">@FELINESITHLORD</p>
          </a>

          <img className="teamMembers-image" src={Mintonomics} alt="" />
          <h1>Mintonomics</h1>
          <p>CREATIVE OPERATIONS</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/mintonomics_eth">
            <p className="twitterHandle">@Mintonomics_ETH</p>
          </a>

          <img className="teamMembers-image" src={Stickyz} alt="" />
          <h1>Stickyz</h1>
          <p>Discord</p>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/Stickyz_nft">
            <p className="twitterHandle">@Stickyz_NFT</p>
          </a>

          <h2>... and 20 Moderators and Team Leads</h2>
        </div>
      </div>
      <div className="mobile-socials">
        <div>
          <a href="/">
            <img className="FelineFiendzIcon" src={FelineFiendzIcon} alt="" />
          </a>
        </div>
        <div className="mobile-socials-right">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://opensea.io/collection/felinefiendznft"
          >
            <img className="OpenseaM" src={OpenseaM} alt="" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/DGpXcvHCEr">
            <img className="Discord" src={Discord} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw"
          >
            <img className="TwitterM" src={TwitterM} alt="" />
          </a>
        </div>
      </div>
      <div className="footer">
        {/* <img className="Footer1" src={Footer1} alt="" /> */}
        <p>© 2022 Fiendz, LLC. All Rights Reserved.</p>
      </div>
      <div>
        {isActive && (
          <div className="NavBar bounce-in-top-ss">
            <Link to="/">
              <img className="home-btn-navbar" src={home} alt="" />
            </Link>
            <div className="NavBar-container">
              <ul>
                <li>
                  <Link to="/roadmap">ROADMAP</Link>
                </li>
                <li>
                  <Link to="/merch">MERCH</Link>
                </li>
                <li>
                  <Link to="/traits">TRAITS</Link>
                </li>
                <li>
                  <Link to="/team">TEAM</Link>
                </li>
                <li>
                  <Link to="/nip">NIP</Link>
                </li>
                <li id="legalLi">
                  <Link to="/legal">
                    <p id="Legal-Navbar"> Legal</p>
                  </Link>
                </li>
              </ul>
            </div>
            <img onClick={toggleClass} className="TapMe2" src={TapMe} alt="" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Team;
