
import React from "react";

import "./Bodega.css";

import BodegaLOGO from "./images/Bodega/BodegaLOGO.svg";
import Season2 from "./images/Bodega/Season2.svg";
import BodegaTwitter from "./images/Bodega/BodegaTwitter.svg";
import BodegaDiscord from "./images/Bodega/BodegaDiscord.svg";
import BLOCK from "./images/Bodega/BLOCK.png";
import Shelf from "./Shelf.js";
import meowTraits from "./images/Bodega/newStickers/MeowTraits_V2.svg";
import Roadmap_V2 from "./images/Bodega/newStickers/Roadmap_V2.svg";
import nipSticker from "./images/Bodega/newStickers/NipSticker_V2.svg";

import pdf from "./FiendzPP.pdf";
import FiendzTC from "./FiendzTC.pdf";



function Bodega() {
  return (
    <div className="Bodega">
      <div className="header">
        <div className="header-left">
          <a href="/">
            <img className="BodegaLOGO" src={BodegaLOGO} alt="" />
          </a>
          <img className="Season2 pulsate-bck" src={Season2} alt="" />
        </div>
        <div className="header-right">
          <h1 className="bodega-h1">
            <a href="/bodega"> BODEGA</a>
          </h1>
          <div className="header-clothing">
            <h1 className="bodega-clothing-h1"> Clothing</h1>
            <img className="BLOCK" src={BLOCK} alt="" />
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw"
          >
            <img className="BodegaTwitter" src={BodegaTwitter} alt="" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/DGpXcvHCEr">
            <img className="BodegaDiscord" src={BodegaDiscord} alt="" />
          </a>
        </div>
      </div>
      <div className="Bodega-Container">
        <div className="left-col">
          <svg
            width="160"
            height="1100"
            viewBox="0 0 160 1100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M157.5 5.02344V2.52344H155H5H2.5V5.02344V1085V1087.5H5H155H157.5V1085V5.02344Z"
              fill="#FCDF99"
              stroke="black"
              stroke-width="5"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.1"
              d="M64.7748 5H5V1084.87H64.7748V5Z"
              fill="black"
            />
          </svg>
        </div>

        <div className="middle">
          <Shelf></Shelf>
        </div>

        <div className="right-col">
          <svg
            width="160"
            height="1100"
            viewBox="0 0 160 1100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 5.02344V2.52344H5H155H157.5V5.02344V1085V1087.5H155H5H2.5V1085V5.02344Z"
              fill="#FCDF99"
              stroke="black"
              stroke-width="5"
              stroke-miterlimit="10"
            />
            <path
              opacity="0.1"
              d="M95.2252 5H155V1084.87H95.2252V5Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div className="Bodega-Footer">
        <div className="Bodega-Footer-Container">
          <div className="box">
            <div className="footer-1">
              <img className="bodega-logo-footer" src={BodegaLOGO} alt="" />
              <p>© 2022 Fiendz, LLC. All Rights Reserved.</p>
            </div>
            <div className="footer-2">
              <h2>Resources</h2>
              <p>
                <a target="_blank" rel="noreferrer" href={FiendzTC}>
                  Terms of Service
                </a>
              </p>
              <p>
                <a target="_blank" rel="noreferrer" href={pdf}>
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="footer-3">
              <h2>Our Litterbox</h2>
              <p>
                <a href="/"> Official Website</a>
              </p>
              <p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://opensea.io/collection/felinefiendznft"
                >
                  Buy Feline Fiendz
                </a>
              </p>
              <p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw"
                >
                  Feline Fiendz Twitter
                </a>
              </p>
            </div>
            <div className="footer-4">
              <h2>Bodega</h2>
              <p>
                <a href="/roadmap">Roadmap</a>
              </p>
              <p>
                <a href="/team">Team</a>
              </p>
              <p>
                <a href="/traits">Traits </a>
              </p>
              <p>
                <a href="/merch">Merch </a>
              </p>
              <p>
                <a href="/nip">Nip </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="meetTheTeam">
        <a href="/nip">
          <img className="meetTheTeamIMG" src={nipSticker} alt="" />
        </a>
      </div>
      <div className="meowTraits">
        <a href="/traits">
          <img className="meowTraitsIMG" src={meowTraits} alt="" />
        </a>
      </div>
      <div className="roadMap">
        <a href="/roadmap">
          <img className="roadmapIMG" src={Roadmap_V2} alt="" />
        </a>
      </div>

      {/* <div>
        <a href="/nip">
          <img src={nipSticker} className="nipIMG" alt="" />
        </a>
      </div> */}

      <div className="Mobiile-V">
        <h1>
          We have engineered the best experience for you on a laptop or mobile.
        </h1>
      </div>
    </div>
  );
}

export default Bodega;
