
import React, { useState } from "react";

import {
  Link,
  useNavigate,
} from "react-router-dom";

import "./MainPage.css";

import ConcreteBase from "./images/page1/ConcreteBase.png";
import YellowBase from "./images/page1/YellowBase.png";
import LeftWindow from "./images/page1/LeftWindow.png";
import RightWindow from "./images/page1/RightWindow.png";
import LeftSpeaker from "./images/page1/Left_speaker_no_wire.png";
import RightSpeaker from "./images/page1/Right_speaker_no_wire.png";
import Door from "./images/page1/Door.png";
import BodegaSticker from "./images/page1/BodegaSticker.png";
import FreeNIP from "./images/page1/FreeNIP.png";
import NFTSticker from "./images/page1/NFTSticker.png";
import RoadMapsticker from "./images/page1/RoadMapsticker.png";
import WlSticker from "./images/page1/WlSticker.png";
import lightsGIF from "./images/page1/lightsGIF.gif";
import home from "./images/Home.png";
import delistSticker from "./images/Stickers/delistSticker.png";
import Discord from "./images/Discord.png";
import TwitterM from "./images/TwitterM.png";
import FelineFiendzIcon from "./images/FelineFiendzIcon.png";
import OpenseaM from "./images/OpenseaM.png";
import discordSticker from "./images/Stickers/discordSticker.png";

import doorGIF from "./images/doorGIF.gif";

import NipMachineSticker from "./images/Stickers/NipMachineSticker.png";
import OpenseaSticker from "./images/Stickers/OpenseaSticker.png";
import RocketSticker from "./images/Stickers/RocketSticker.png";
import Nip1sticker from "./images/Stickers/Nip1sticker.png";
import meowSticker from "./images/Stickers/meowSticker.png";
import FreenipSticker from "./images/Stickers/FreenipSticker.png";

import TapMe from "./images/page1/TapMe.png";

import { motion } from "framer-motion";

function MainPage() {
  const [isActive, setActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  function wait(time) {
    return new Promise((resolve) => {
      setTimeout(resolve, time);
    });
  }
  async function goToPage() {
    // it will navigate to the page 500ms after clicing the div
    await wait(1500);
    navigate(`/bodega`);
  }
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <motion.div
      // onTap={{
      //   scale: [1, 1.2, 1.4, 1.6, 1.8],
      //   transition: { duration: 0.1 },
      // }}
      initial={{ opacity: 1 }}
      animate={isOpen ? { scale: 2 } : {}}
      transition={{ duration: 0.1 }}
    >
      <div className="mainPage">
        <div className="App">
          <div>
            <img
              className="lightsGIF bounce-in-top-xxl"
              src={lightsGIF}
              alt=""
            />
            <img
              className="level-1 bounce-in-top-s"
              src={ConcreteBase}
              alt=""
            />
            <img
              className="level-2-l bounce-in-top-m"
              src={LeftWindow}
              alt=""
            />
            <img
              className="level-2-r bounce-in-top-m"
              src={RightWindow}
              alt=""
            />

            <img className="level-0 bounce-in-top-l" src={YellowBase} alt="" />

            <img className="door bounce-in-top-xl" src={Door} alt="" />

            <div className="level-3 bounce-in-top-xl">
              <img className="level-3-l pulsate-fwd" src={LeftSpeaker} alt="" />
              <img
                className="level-3-r pulsate-fwd"
                src={RightSpeaker}
                alt=""
              />
            </div>
            <img
              className="bodega-sticker bounce-in-top-xl"
              src={BodegaSticker}
              alt=""
            />
            <div className="level-4 bounce-in-top-xl">
              <img className="heartbeat" src={RoadMapsticker} alt="" />
              <img src={NFTSticker} alt="" />
              <img src={FreeNIP} alt="" />
              <img src={WlSticker} alt="" />
            </div>
          </div>
          <div className="level-5 bounce-in-top-xxl">
            <img className="meowSticker" src={meowSticker} alt="" />
            <img
              className=" wobble-ver-left  NipMachineSticker "
              src={NipMachineSticker}
              alt=""
            />
            <img
              className="shake-left RocketSticker"
              src={RocketSticker}
              alt=""
            />
            <img
              className="jello-vertical OpenseaSticker"
              src={OpenseaSticker}
              alt=""
            />
          </div>
          <div className="level-6 bounce-in-top-xxxl">
            <img className="FreenipSticker  " src={FreenipSticker} alt="" />
            <img className="discordSticker " src={discordSticker} alt="" />
            <img className="Nip1sticker  " src={Nip1sticker} alt="" />
            <img className="delistSticker  " src={delistSticker} alt="" />
          </div>
        </div>
        <div onClick={goToPage} className="nextPageBTN bounce-in-top-xxxl">
          {/* <Link to="/bodega"> */}
          <button
            onClick={() => setIsOpen((isOpen) => !isOpen)}
            className="getIn"
          ></button>
          {/* </Link> */}
        </div>
        {isOpen && <img className="doorGIF " src={doorGIF} alt="doorGIF" />}
        <img
          onClick={toggleClass}
          className="TapMe bounce-in-top-xxxl"
          src={TapMe}
          alt=""
        />
        <div className="mobile-socials">
          <div>
            <a href="/">
              <img className="FelineFiendzIcon" src={FelineFiendzIcon} alt="" />
            </a>
          </div>
          <div className="mobile-socials-right">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://opensea.io/collection/felinefiendznft"
            >
              <img className="OpenseaM" src={OpenseaM} alt="" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://discord.gg/DGpXcvHCEr">
              <img className="Discord" src={Discord} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw"
            >
              <img className="TwitterM" src={TwitterM} alt="" />
            </a>
          </div>
        </div>
        <div className="footer">
          {/* <img className="Footer1" src={Footer1} alt="" /> */}
          <p>© 2022 Fiendz, LLC. All Rights Reserved.</p>
        </div>
        <div className="ipad">
          <h1>
            We have engineered the best experience for you on a laptop or
            mobile.
          </h1>
        </div>
        {isActive && (
          <div className="NavBar bounce-in-top-ss">
            <Link to="/">
              <img className="home-btn-navbar" src={home} alt="" />
            </Link>
            <div className="NavBar-container">
              <ul>
                <li>
                  <Link to="/roadmap">ROADMAP</Link>
                </li>
                <li>
                  <Link to="/merch">MERCH</Link>
                </li>
                <li>
                  <Link to="/traits">TRAITS</Link>
                </li>
                <li>
                  <Link to="/team">TEAM</Link>
                </li>
                <li>
                  <Link to="/nip">NIP</Link>
                </li>
                <li id="legalLi">
                  <Link to="/legal">
                    <p id="Legal-Navbar"> Legal</p>
                  </Link>
                </li>
              </ul>
            </div>
            <img onClick={toggleClass} className="TapMe2" src={TapMe} alt="" />
          </div>
        )}
      </div>
    </motion.div>
  );
}

export default MainPage;
