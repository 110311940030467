import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import "./Traits.css";

import TapMe from "./images/page1/TapMe.png";
import trait1 from "./images/trait1.png";
import trait2 from "./images/trait2.png";
import home from "./images/Home.png";
import Discord from "./images/Discord.png";

import TwitterM from "./images/TwitterM.png";
import FelineFiendzIcon from "./images/FelineFiendzIcon.png";
import OpenseaM from "./images/OpenseaM.png";

function Traits() {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <div className="traits">
      <img
        onClick={toggleClass}
        className="TapMe bounce-in-top-ss"
        src={TapMe}
        alt=""
      />
      <Link className="linkToStore" to="/bodega">
        <img alt="" className="homeBTN" src={home}></img>
      </Link>

      <img src={trait1} alt="" />
      <img className="trait2" src={trait2} alt="" />
      <div className="mobile-socials">
        <div>
          <a href="/">
            <img className="FelineFiendzIcon" src={FelineFiendzIcon} alt="" />
          </a>
        </div>
        <div className="mobile-socials-right">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://opensea.io/collection/felinefiendznft"
          >
            <img className="OpenseaM" src={OpenseaM} alt="" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://discord.gg/DGpXcvHCEr">
            <img className="Discord" src={Discord} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/FelineFiendz?s=20&t=U6rEQLd-JeH7pHWcduzhqw"
          >
            <img className="TwitterM" src={TwitterM} alt="" />
          </a>
        </div>
      </div>
      <div className="footer">
        {/* <img className="Footer1" src={Footer1} alt="" /> */}
        <p>© 2022 Fiendz, LLC. All Rights Reserved.</p>
      </div>
      <div>
        {isActive && (
          <div className="NavBar bounce-in-top-ss">
            <Link to="/">
              <img className="home-btn-navbar" src={home} alt="" />
            </Link>
            <div className="NavBar-container">
              <ul>
                <li>
                  <Link to="/roadmap">ROADMAP</Link>
                </li>
                <li>
                  <Link to="/merch">MERCH</Link>
                </li>
                <li>
                  <Link to="/traits">TRAITS</Link>
                </li>
                <li>
                  <Link to="/team">TEAM</Link>
                </li>
                <li>
                  <Link to="/nip">NIP</Link>
                </li>
                <li>
                  <Link to="/legal">
                    <p id="Legal-Navbar"> Legal</p>
                  </Link>
                </li>
              </ul>
            </div>
            <img onClick={toggleClass} className="TapMe2" src={TapMe} alt="" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Traits;
